var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{class:[
    'd-flex flex-column justify-center align-center secondary rounded',
    { 'forced-black': _vm.isRewardedPost && _vm.previewIsShown }
  ],attrs:{"elevation":"0"}},[_c('v-expand-transition',[(_vm.previewIsShown)?_c('div',{class:[{ 'width-100': _vm.isRewardedPost && !_vm.isTabletOrLg }]},[_c('v-carousel',{staticClass:"rounded",attrs:{"height":_vm.isRewardedPost ? 710 : 150,"hide-delimiters":"","hide-delimiter-background":"","show-arrows":_vm.ad.model.creatives.length > 1}},_vm._l((_vm.creatives),function(creative,index){return _c('v-carousel-item',{key:index},[_c('instream-preview',{attrs:{"creative":{
              ...creative,
              type: _vm.ad.model.type,
              skipTime: _vm.ad.model.skipTime
            }},on:{"end":function($event){_vm.previewIsShown = $event}}})],1)}),1)],1):_vm._e()]),_c('v-card-actions',{staticClass:"d-flex justify-center pa-2",attrs:{"transition":"scroll-x-transition"}},[_c('c-btn',{attrs:{"small":"","depressed":"","label":_vm.previewIsShown ? _vm.$t('ads.hide_preview') : _vm.$t('ads.show_preview'),"color":"primary"},on:{"click":function($event){return _vm.onPreviewBtnClick()}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }