<template>
  <v-card
    elevation="0"
    :class="[
      'd-flex flex-column justify-center align-center secondary rounded',
      { 'forced-black': isRewardedPost && previewIsShown }
    ]"
  >
    <v-expand-transition>
      <div
        v-if="previewIsShown"
        :class="[{ 'width-100': isRewardedPost && !isTabletOrLg }]"
      >
        <v-carousel
          class="rounded"
          :height="isRewardedPost ? 710 : 150"
          hide-delimiters
          hide-delimiter-background
          :show-arrows="ad.model.creatives.length > 1"
        >
          <v-carousel-item v-for="(creative, index) in creatives" :key="index">
            <instream-preview
              :creative="{
                ...creative,
                type: ad.model.type,
                skipTime: ad.model.skipTime
              }"
              @end="previewIsShown = $event"
            />
          </v-carousel-item>
        </v-carousel>
      </div>
    </v-expand-transition>
    <v-card-actions transition="scroll-x-transition" class="d-flex justify-center pa-2">
      <c-btn
        small
        depressed
        :label="previewIsShown ? $t('ads.hide_preview') : $t('ads.show_preview')"
        color="primary"
        @click="onPreviewBtnClick()"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
  import { adTypes } from '@clickadilla/components/constants/ad-types.js'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import Ads from '@/services/classes/Ads.js'
  import InstreamPreview from '@/components/ads/InstreamForm/InstreamPreview.vue'

  export default {
    name: 'InstreamCreativePreview',
    components: {
      InstreamPreview,
      CBtn
    },
    props: {
      ad: {
        type: Ads,
        required: true
      }
    },
    data() {
      return {
        previewIsShown: false,
        previewDialogIsShown: false
      }
    },
    computed: {
      creatives() {
        return this.ad.model.creatives
      },
      isTabletOrLg() {
        return this.$vuetify.breakpoint.smOnly || this.$vuetify.breakpoint.lgAndUp
      },
      isRewardedPost() {
        return this.ad.model.type === adTypes.REWARDED_POST
      }
    },
    methods: {
      onPreviewBtnClick() {
        this.previewIsShown = !this.previewIsShown
        this.$emit('expand', this.previewIsShown)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .forced-black {
    background-color: #000 !important;
  }
  .width-100 {
    width: 100%;
  }
</style>
